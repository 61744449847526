const { del } = require("object-path");

const DataService = {
  fillMetadata(resource) {
    if (Array.isArray(resource)) {
      resource.forEach((e) => {
        this.spreadObject(e);
      });
    } else {
      this.spreadObject(resource);
    }

    return resource;
  },

  spreadObject(obj) {
    if (obj.metadata) {
      Object.keys(obj.metadata).forEach((k) => {
        obj[k] = obj.metadata[k];
      });
      del(obj.metadata);
    }
  },
};

export default DataService;
