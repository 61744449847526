// USA
export const locale = {
  menu: {
    dashboard: "Dashboard",
    address: "Address",
    addresses: "Addresses",
    offer: "Offer",
    offers: "Offers",
    project: "Project",
    projects: "Projects",
    invoice: "Invoice",
    invoices: "Invoices",
    expense: "Expense",
    timesheet: "Timesheet",
    product: "Product",
    settings: "Settings",
    fullscr: "Full Screen",
    group: "Product Group",
    report: "Report",
    timeOverview: "Time Overview"
  },
  title: {
    login: "Login",
    forgotPass: "Forgot Password",
    changePass: "Password",
    profile: "User Profile",
    changeAvatar: "Avatar",
    activateUser: "Activate User",
    addUser: "Add User",
    createOffer: "Create Offer",
    createAddress: "Create Address",
    createProject: "Create Project",
    createInvoice: "Create Invoice",
    createExpense: "Create Expense",
    createTimesheet: "Create Timesheet",
    createProduct: "Create Product",
    createGroup: "Create Product Group",
    editOffer: "Edit Offer",
    editAddress: "Edit Address",
    editProject: "Edit Project",
    editInvoice: "Edit Invoice",
    editPartialInvoice: "Edit Partial Invoice",
    editFinalInvoice: "Edit Final Invoice",
    editExpense: "Edit Expense",
    editTimesheet: "Edit Timesheet",
    editProduct: "Edit Product",
    editGroup: "Edit Product Group",
    overview: "Overview",
    confirm: "Confirm",
    announce: "Announce",
    acceptOffer: "Accepted Offer",
    revenue: "Revenue",
    log: "Log Work",
    notice: "Notice",
    submitReport: "Submit Report",
    unlockReport: "Unlock Report",
    effortRecord: "Effort Record",
    updateStatus: "Update Status",
    advancedWorkload: "Advanced Workload",
    workItemList: "Work item List",
    connectProject: "Connect Projects",
    myHoliday: "Update Holiday",
    myTimeReport: "Time report",
    holidayHistory: "Holiday History"
  },
  button: {
    login: "Login",
    logout: "Logout",
    register: "Register",
    changePass: "Change Password",
    back: "Back",
    reset: "Reset",
    add: "Add New",
    save: "Save",
    delete: "Delete",
    confirm: "Confirm",
    update: "Update",
    upload: "Upload",
    download: "Download",
    print: "Print",
    mail: "Send Email",
    copy: "Copy",
    copyAsOffer: "Copy As Offer",
    show: "Show",
    entries: "entries",
    search: "Search",
    selectCustomer: "Select a customer",
    product: "Product",
    group: "Product Group",
    individual: "Individual",
    title: "Title",
    textfield: "Textfield",
    additional: "Additional",
    acceptOffer: "Offer Accepted",
    invoice: "Invoice/Partial invoice",
    cancelInvoice: "Cancel invoice",
    paidInvoice: "Invoice paid",
    activeUser: "Active User",
    reminder1: "Reminder 1",
    reminder2: "Reminder 2",
    noReminder: "Invoice",
    generate: "Generate",
    breakPage: "Break Page",
    lockWork: "Submit monthly report",
    unlockWork: "Unlock report",
    effortRecord: "Effort Record",
    printAll: "Print All",
    printpdf: "Print pdf",
    printdocx: "Print docx",
    printexcel: "Print xlsx",
    reloadTimesheet: "Reload Timesheet",
    preview: "Preview",
    holiday: "My Holiday",
    timeReport: "Time Report",
    internal: "Internal",
    myCompanyOnly: "Only my company",
    refresh: "Refresh"
  },
  msg: {
    delAdr: "Are you sure you want to delete this address?",
    delOffer: "Are you sure you want to delete this offer?",
    delProject: "Are you sure you want to delete this project?",
    delInvoice: "Are you sure you want to delete this invoice?",
    delExpense: "Are you sure you want to delete this expense?",
    delProduct: "Are you sure you want to delete this product?",
    delGroup: "Are you sure you want to delete this product group?",
    delUser: "Are you sure you want to delete this user?",
    delContact: "Are you sure you want to delete this contact?",
    delConnection: "Are you sure you want to delete this connection?",
    delHoliday: "Are you sure you want to delete this holiday?",
    required: "Required!",
    invalidMail: "Invalid Email Address!",
    agreeTerm: "You must agree to terms and conditions of Archapp.",
    registerSuccess:
      "Create successful! Please check register email to get link active.",
    noFile: "No file selected!",
    noCustomer: "No customer selected!",
    updateSuccess: "Update item successful!",
    addSuccess: "Add item successful!",
    delSuccess: "Delete item successful!",
    copySuccess: "Copy item successful!",
    uploadSuccess: "Upload item successful!",
    resetSuccess: "Successful! Please check email to get reset link.",
    changeSuccess: "Change successful!",
    activateSuccess: "Activate user successful!",
    exportSuccess: "Export item successful!",
    cancelSuccess: "Cancel item successful!",
    paidSuccess: "Paid item successful!",
    disablePopup: "Please disable your pop-up blocker and try again!",
    selectFile: "Choose a file or drop it here...",
    dropFile: "Drop file here...",
    lastGenerated: "Last generated document:",
    updateStatus: "Update status for these items",
    confirmPrint: "Print all will take a while. Please be patient.",
    multipleCompanies:
      "Your email is registered to multiple companies. Please select one!",
    missingStartdate: "Missing Start Date!",
    duplicateProduct: "Duplicate product!"
  },
  email: {
    salutation: "Dear Sir or Madam",
    end: "Kind regards",
    offerIntro:
      "Thank you for your valued order. You can find an overview of the services we agreed on under the following link:",
    offerEnd:
      "We are happy to be at your disposal for any additions, corrections or questions.\n\nWe look forward to working with you!",
    projectIntro:
      "Thank you for your valued order. You can find an overview of the services we agreed on under the following link:",
    invoiceIntro:
      "Thank you for your valued order. You can find an overview of the services we agreed on under the following link:"
  },
  unit: {
    flatRate: "Flat rate",
    flat: "",
    hours: "Hours",
    hr: "hr",
    kilometres: "Kilometres",
    km: "km",
    squareMetres: "Square metres",
    m2: "m2",
    expenses: "Hours according to expenses",
    items: "Items",
    item: "Items",
    page: "Page"
  },
  status: {
    all: "All",
    active: "Active",
    disabled: "Disabled",
    pending: "Pending",
    archive: "Archive",
    deleted: "Deleted",
    paid: "Paid",
    cancelled: "Cancelled",
    reminder1: "Reminder 1",
    reminder2: "Reminder 2",
    open: "Open",
    offset: "Offset",
    reminder: "Reminder",
    internal: "Internal"
  },
  login: {
    remind:
      "Please use your credentials to login. If you are not a member, please",
    forgotPass: "Forgot Password ?",
    already: "Already have an account?",
    noAcc: "Don't have an account yet?",
    guide: "Enter your username and password"
  },
  forgotPass: {
    mail: "Email",
    remind: "Please use your e-mail to reset your password."
  },
  register: {
    welcome: "Welcome to Archapp.",
    desc:
      // eslint-disable-next-line max-len
      "In just 2 easy steps, set up your personal Archapp installation here. This is available to you for 30 days free of charge and without obligation for testing - and if we have convinced you afterwards that you want to continue to write your offers, invoices, etc. so quickly and conveniently, you can continue to use your data without interruption - just simply smart.",
    company: "Your Company",
    mail: "Email",
    currency: "Your Currency",
    vat: "The VAT",
    vatDesc:
      "Are you subject to VAT? If you activate this option this can not be undone! However, you can always activate the VAT functionality later in the settings of your Archapp installation if you do not currently select this option.",
    template: "Architektur Vorlage",
    templateDesc: "Dienstleistungen nach SIA",
    term: "The formalities",
    termDesc: "I accept the terms and conditions for the use of Archapp."
  },
  verifyRegister: {
    remind: "Please enter password.",
    password: "Password",
    repeatPassword: "Repeat Password"
  },
  dashboard: {
    hello: "Hi",
    date: "Date",
    customer: "Customer",
    name: "Project",
    summary: "Summary",
    activeOffer: "Active Offers",
    activeProject: "Projects",
    activeInvoice: "Invoices",
    currentPass: "Current Password",
    newPass: "New Password",
    repeatPass: "Repeat New Password",
    thousands: "thousands",
    sum: "Sum",
    invoiced: "Invoiced",
    remain: "Remaining",
    totalHour: "Hours Total",
    loggedHour: "Hours Written",
    net: "Net",
    gross: "Gross",
    sumNet: "Total sum net",
    sumGross: "Total sum gross",
    remainNet: "Remaining net",
    remainGross: "Remaining gross",
    sumActive: "Active projects",
    sumInvoice: "Invoices",
    reminder: "Reminders",
    notCharged: "Not charged",
    projectHour: "Projects Hours",
    projectLogged: "Logged Hours",
    projectHourDif: "Hour Difference",
    sumActiveThisYear: "This year active projects",
    projectHourDifThisYear: "This year hour diff",
    diffProjectInvoice: "Project sum and Oven invoices difference",
    totalOpenInvoiceGross: "Open invoices gross",
    totalOpenReminderGross: "Open reminders gross",
    totalOvertime: "Overtime projects",
    totalOfferNetAmount: "Total net",
    totalOfferGrossAmount: "Total gross",
    totalProjectAmount: "Total amount",
    totalRemainingAmount: "Remaining amount",
    totalOustandingNetAmount: "Outstanding net",
    totalOustandingGrossAmount: "Outstanding gross"
  },
  address: {
    name: "Company/Name",
    company: "Name/Company",
    category: "Category",
    type: "Type",
    address: "Address",
    otheradr: "Other address",
    postcode: "Postcode",
    place: "Place",
    mail: "Email",
    phone: "Phone",
    fax: "Fax",
    country: "Country",
    web: "Website",
    comment: "Comment",
    contact: "Contact Person",
    title: "Title",
    firstName: "First name",
    lastName: "Last name",
    contactName: "Name",
    givenName: "Given name",
    mobile: "Mobile phone",
    wmail: "Work email",
    prmail: "Private email",
    dob: "Day of birth",
    internal: "internal",
    typeCompany: "Company",
    typePerson: "Private Person",
    typeMr: "Mr",
    typeMs: "Ms",
    typeFamily: "Family",
    catCustomer: "Customer",
    catAcquisition: "Aquisition",
    catSupplier: "Supplier",
    actions: "Actions"
  },
  offer: {
    active: "Active",
    name: "Company/Name",
    title: "Project",
    number: "Project Nr.",
    date: "Date",
    status: "Status",
    customer: "Customer/Address",
    offer: "Project Title",
    location: "Project Location",
    internalDesc: "Internal description (will not appear on order/invoice)",
    additional: "Additional costs for",
    acceptText1:
      "The customer has accepted the offer, thus the order is now transformed into a project.",
    acceptText2:
      "If required, you can select a completion date for this project. Otherwise simply leave the field empty.",
    projectComplete: "Project Completion",
    from: "from",
    addcostInvoice: "Additional Costs",
    actions: "Actions",
    messerliCal: "Calculation According To Construction Costs",
    freeCal: "Free Calculation",
    kilometers: "Kilometers",
    genType: "Hour Rate",
    sum: "Sum",
    net: "Net",
    gross: "Gross"
  },
  project: {
    name: "Company/Name",
    customer: "Customer/Address",
    title: "Title",
    project: "Project",
    assignee: "Assignee",
    date: "Date",
    location: "Location",
    number: "Project Nr.",
    created: "Created on",
    ended: "Ended on",
    internalDesc: "Internal description (will not appear on order/invoice)",
    invoiceText1:
      "To invoice the entire amount, click on the OK button without making any changes. If you want to create a partial invoice, enter the amount that is to be invoiced below.",
    invoiceText2: "Comment on value added tax!",
    attention: "Attention",
    attentionText:
      "Partial invoices can only be created for projects with the same VAT rate. When you create a partial invoice, the rate for that invoice is fixed and cannot be changed. New positions with a different VAT rate cannot be added either.",
    invoiceAmount: "Invoice amount (exclusive of VAT)",
    percent: "Percent",
    service: "Service",
    hour: "Hour",
    totalAmount: "Total amount",
    remaining: "Remaining",
    completion: "Project Completion",
    actions: "Actions",
    additional: "Additional Costs",
    invoice: "Invoice",
    from: "from",
    start: "Start",
    end: "End",
    noRecord: "No record available.",
    rate: "Rate",
    task: "Task",
    spent: "Spent",
    sum: "Sum",
    totalHour: "Total Hours",
    totalRate: "Cost",
    invoiced: "Invoiced",
    remain: "Remaining",
    allHour: "Hours Total",
    loggedHour: "Hours Written",
    beginOfTime: "From beginning",
    thisMonth: "This month",
    lastMonth: "Last month",
    net: "Net",
    gross: "Gross",
    noConnection: "No connection",
    connections: "Connections",
    hourRate: "Rate per hour",
    charged: "charged",
    orderTotal: "Total",
    remainingClaim: "Remaining",
    invoicedTotal: "Invoiced"
  },
  invoice: {
    name: "Company/Name",
    title: "Project",
    number: "Project Nr.",
    charged: "RG Date",
    due: "Due on",
    paid: "Paid date",
    amount: "Amount",
    amountNet: "Net Amount",
    invoice: "Invoice",
    customer: "Customer/Address",
    date: "Date",
    location: "Location",
    payable: "payable within",
    days: "days",
    slipNumber: "Payment slip reference number",
    internalDesc: "Internal description (will not appear on order/invoice)",
    invoiceNum: "Invoice number",
    refNum: "Reference number",
    totalNet: "Total net",
    totalGross: "Total gross",
    valueDate: "Value Date",
    comment: "Comment",
    actions: "Actions",
    history: "Payment details",
    partialInvoice: "Partial invoice",
    finalInvoice: "Final invoice",
    paidHistory: "Paid History",
    remain: "Remaining",
    listHistory: "Invoice Overview",
    from: "from",
    customTitle: "Custom title",
    customDate: "Custom date",
    includeInternalHourReport: "Include Internal Hour Report",
    includeExternalHourReport: "Include External Hour Report",
    reportFrom: "Report From",
    reportTo: "Report To",
    paidTitle: "Paid",
    qr: "QR Nr."
  },
  expense: {
    name: "Recipient(Username)",
    paymentRef: "Payment Reference",
    date: "Date",
    amount: "Amount",
    recipient: "Recipient",
    comment: "Comment",
    attachment: "Attachment",
    upload: "Add Attachment",
    actions: "Actions"
  },
  product: {
    name: "Name",
    gname: "Group Name",
    desc: "Description",
    group: "Group",
    unit: "Unit",
    vat: "VAT",
    price: "Price",
    rate: "VAT Rate",
    included: "Service included",
    allServices: "All services",
    actions: "Actions",
    task: "Tasks",
    folder: "Folder"
  },
  timesheet: {
    startDate: "Start day",
    endDate: "End day",
    project: "Project Name + Phase",
    service: "Service",
    task: "Task",
    comment: "Comment",
    total: "Total",
    mustWork: "Must work",
    dailyTotal: "Total hours",
    diff: "Different",
    assignee: "Assignee",
    thisWeek: "This Week",
    thisMonth: "This Month",
    month: "Month",
    year: "Year",
    users: "Users",
    noUser: "No User Available.",
    confirmReport: "Confirm your submit. This action can't be reverted.",
    billable: "Billable",
    internal: "Internal",
    all: "All",
    flexible: "Flexible hours",
    personal: "My Timesheet",
    viewOption: "View Option",
    showAll: "Show All"
  },
  report: {
    listUser: "List of current employees",
    listJob: "List of current employees with job and rate",
    listAddress: "List of addresses",
    listProject: "List of projects",
    listOffer: "List of offers",
    listInvoice: "List of invoices",
    listEffortExternal:
      "External expenses according to orders, partial services and fee categories",
    listEffortInternal:
      "Internal expenses according to orders, partial services and fee categories",
    listPhaseEffort: "Expenses according to partial services",
    listUserEffort: "Employee expenses according to orders",
    listHoliday: "Expenses according to  Flexible time and Holiday",
    listHour: "Hours excerpt",
    timeOnly: "Time only",
    byHoliday: "By Flexible time and Holiday",
    byUserEffort: "By Effort",
    singleProject: "Projects",
    singleProjectSummary: "Projects Summary",
    singleUser: "Employees",
    singleInvoice: "Invoices",
    singleAddress: "Addresses",
    singleOffer: "Offers",
    byPhase: "By partial services",
    byTask: "By tasks",
    byAllInvoices: "All Invoices",
    internal: "Internal Export",
    external: "External Export",
    projectSummary: "Project summary"
  },
  timeOverview: {
    projectTime: "Project hours",
    productive: "Productive Time",
    unproductive: "Unproductive Time",
    revenue: "Revenue",
    invoiced: "Invoiced",
    uninvoiced: "Uninvoiced",
    project: "Project",
    number: "Project Nr.",
    employee: "Employee",
    unbillable: "Internal Hours",
    totalHours: "Should have hours",
    thisWeek: "This Week",
    thisMonth: "This Month",
    lastMonth: "Last Month",
    thisYear: "This Year",
    today: "Today",
    notedHours: "Total Noted hours",
    totalAvailable: "Total Available Hours",
    all: "All",
    position: "Position",
    totalSum: "Total Sum",
    hourProject: "Hours Project",
    hourUsed: "% hours used",
    rate: "Won/Lost",
    submitted: "MA Reports",
    status: "Status"
  },
  settings: {
    master: "Master Data",
    user: "User",
    unit: "Unit",
    rate: "VAT Rate",
    offer: "Offer",
    order: "Order Confirmation",
    invoice: "Invoice",
    timesheet: "Timesheet",
    pdf: "PDF Layout",
    sharing: "Sharing",
    product: "Product",
    form: {
      master: {
        company: "Company",
        address: "Address",
        place: "Place",
        country: "Country",
        postcode: "Postcode",
        phone: "Phone",
        fax: "Fax",
        mail: "Email",
        web: "Website",
        currency: "Currency",
        bank: "Bank",
        clearing: "Clearing number",
        iban: "IBAN/account number",
        revenue: "Revenue",
        year: "Year",
        data: "Data",
        options: "Options",
        allow_to_use_other_currencies:
          "Allow to use other currencies in documents"
      },
      user: {
        firstName: "First name",
        lastName: "Last name",
        level: "Authorization",
        active: "Active",
        lvAuth: "Level of authorization",
        text1:
          "The level of authorisation determines which functions the new user can access:",
        text2: "Full: unlimited access.",
        text3: "Restricted: without access to services, lists and settings.",
        text4: "Employees: time recording.",
        lvAdmin: "Admin",
        lvPM: "Project Manager",
        lvEmployee: "Employee",
        actions: "Actions",
        maxWeekly: "Workload %",
        maxDaily: "Hours per day",
        postcode: "Postcode",
        phone: "Phone",
        place: "Place",
        address: "Address",
        job: "Job",
        dob: "Birthday",
        startWork: "Start working date",
        endWork: "End working date",
        holidays: "Holidays",
        workload: "days per week",
        email: "Email",
        startDate: "Start Date",
        endDate: "End Date",
        latestFlex: "Remaining Flex Hours",
        amount: "Amount",
        latestHours: "Previous hours",
        rsPlanning: "Performance %"
      },
      unit: {
        desc: "Description",
        unit: "Unit",
        visible: "Visible",
        default: "Default",
        timeRecord: "Time recording",
        timeRecordActive: "Time recording activated?",
        text1:
          "This setting activates the unit for the time recording system, i.e. you can enter the time you spent on each position that was recorded with this unit directly in Archapp.",
        text2:
          'Therefore, it is especially suitable for units that are invoiced "according to expenses" or hours'
      },
      vat: {
        rate: "VAT Rate",
        visible: "Visible",
        default: "Default",
        percent: "Percent"
      },
      offer: {
        label: "Label",
        value: "Value",
        dropdownSetting: "OfferKmDropdownSettingTable",
        dropdownLabel: "Dropdown label",
        addcostSetting: "OfferAddCostSettingTable",
        percentLabel: "Percent label",
        subtitle: "Subtitle"
      },
      project: {
        duration: "Project duration in days",
        begin: "Beginning of text",
        end: "End of text",
        address: "Address"
      },
      invoice: {
        duration: "Payment deadline in days",
        begin: "Beginning of text",
        end: "End of text",
        durationRe1: "Reminder 1 Payment deadline in days",
        durationRe2: "Reminder 2 Payment deadline in days",
        beginRe1: "Reminder 1 Beginning of text",
        endRe1: "Reminder 1 End of text",
        beginRe2: "Reminder 2 Beginning of text",
        endRe2: "Reminder 2 End of text",
        qr: "Include QR code",
        hint: "Use [duration] to replace deadline value in your text"
      },
      timesheet: {
        working: "Must working hours",
        maxWork: "Max working hours per week",
        holidays: "Holidays",
        desc: "Description",
        date: "Date",
        jobs: "Jobs",
        title: "Title",
        rate: "Rate",
        defaultHoliday: "Default holiday task"
      },
      pdf: {
        logo: "Logo(PNG or JPEG)",
        scale: "Scalings",
        logoPos: "Logo Position",
        adrPos: "Address Position",
        font: "pdfFont",
        footerCustom: "PDF Footer Custom",
        headerCustom: "PDF Header Custom",
        pgNum: "Page number",
        showFooter: "Show Footer",
        showHeader: "Show Header",
        showNum: "Show page numbers"
      },
      sharing: {
        generateToken: "Generate Token",
        connections: "Connection List",
        connection: "Connection",
        actions: "Actions"
      },
      product: {
        folder: "Folder",
        name: "Name"
      }
    }
  },
  customer: {
    name: "Company/Name",
    category: "Category",
    address: "Address",
    postcode: "Postcode",
    place: "Place",
    mail: "Email",
    phone: "Phone"
  },
  quoteItems: {
    service: "Service",
    optional: "Optional",
    unit: "Unit",
    amount: "Amount",
    vat: "VAT",
    price: "Price",
    cost: "Costs",
    discountPercent: "Discount Percent",
    discount: "Discount",
    applyDiscount: "Apply Discount",
    net: "Total Net",
    gross: "Total Gross incl. VAT",
    titleCost: "Total:",
    netRemaining: "Net remaining amount",
    grossRemaining: "Gross remaining amount",
    invoice: "Invoice",
    partial: "Partial invoice",
    from: "from",
    netpdf: "Total excluding VAT",
    vatpdf: "VAT",
    grosspdf: "Total including VAT",
    total: "Total incl. Discount",
    totalNoDiscount: "Total excl. Discount",
    finalInvoice: "Final invoice",
    planning: "Planning"
  },
  months: {
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    janFull: "January",
    febFull: "February",
    marFull: "March",
    aprFull: "April",
    mayFull: "May",
    junFull: "June",
    julFull: "July",
    augFull: "August",
    sepFull: "September",
    octFull: "October",
    novFull: "November",
    decFull: "December"
  },
  countries: {
    AF: "Afghanistan",
    AX: "Åland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "AndorrA",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, The Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic Of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People'S Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People'S Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, The Former Yugoslav Republic of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "RWANDA",
    SH: "Saint Helena",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    CS: "Serbia and Montenegro",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
  }
};
