// Germany
export const locale = {
  menu: {
    dashboard: "Übersicht",
    address: "Adresse",
    addresses: "Adressen",
    offer: "Offerte",
    offers: "Offerten",
    project: "Projekt",
    projects: "Projekte",
    invoice: "Rechnung",
    invoices: "Rechnungen",
    expense: "Spesen",
    timesheet: "Zeiterfassung",
    product: "Produkte/Dienstleistungen",
    settings: "Einstellungen",
    fullscr: "Vollbild",
    group: "Produktgruppe",
    report: "Bericht",
    timeOverview: "Zeitübersicht"
  },
  title: {
    login: "Anmelden",
    forgotPass: "Passwort vergessen",
    changePass: "Passwort",
    profile: "Benutzerprofil",
    changeAvatar: "Profilbild",
    activateUser: "Benutzer aktivieren",
    addUser: "Benutzer hinzufügen",
    createOffer: "Offerte hinzufügen",
    createAddress: "Adresse hinzufügen",
    createProject: "Projekt hinzufügen",
    createInvoice: "Rechnung hinzufügen",
    createExpense: "Spesen hinzufügen",
    createTimesheet: "Zeiterfassung hinzufügen",
    createProduct: "Produkt hinzufügen",
    createGroup: "Produktgruppe hinzufügen",
    editOffer: "Offerte bearbeiten",
    editAddress: "Adresse bearbeiten",
    editProject: "Projekt bearbeiten",
    editInvoice: "Rechnung bearbeiten",
    editPartialInvoice: "Teilrechnung bearbeiten",
    editFinalInvoice: "Schlussrechnung bearbeiten",
    editExpense: "Spesen bearbeiten",
    editTimesheet: "Zeiterfassung bearbeiten",
    editProduct: "Produkt bearbeiten",
    editGroup: "Produktgruppe bearbeiten",
    overview: "Aktuell",
    confirm: "Bestätigen",
    announce: "Meldung",
    acceptOffer: "Akzeptiertes Angegbot",
    revenue: "Umsatz (ohne Spesen)",
    log: "Protokollarbeit",
    notice: "Mitteilung",
    submitReport: "Bericht einreichen",
    unlockReport: "Bericht entsperren",
    effortRecord: "Aufwandsaufzeichnung",
    updateStatus: "Status aktualisieren",
    advancedWorkload: "Erweiterte Arbeitsbelastung",
    workItemList: "Liste der Arbeitselemente",
    connectProject: "Projekte verbinden",
    myHoliday: "Urlaub aktualisieren",
    myTimeReport: "Hours excerpt",
    holidayHistory: "Vergangene Urlaube"
  },
  button: {
    login: "anmelden",
    logout: "abmelden",
    register: "registrieren",
    changePass: "Passwort ändern",
    back: "zurück",
    reset: "zurücksetzen",
    add: "neu hinzufügen",
    save: "speichern",
    delete: "löschen",
    confirm: "bestätigen",
    update: "aktualisieren",
    upload: "hochladen",
    download: "herunterladen",
    print: "drucken",
    mail: "E-Mail senden",
    copy: "kopieren",
    copyAsOffer: "Als Offerte kopieren",
    show: "zeigen",
    entries: "Einträge",
    search: "Suche",
    selectCustomer: "Einen Kunden auswählen",
    product: "Produkt",
    group: "Produktgruppe",
    individual: "Individuell",
    title: "Titel",
    textfield: "Textfeld",
    additional: "Nebenkosten",
    acceptOffer: "Angebot akzeptiert",
    invoice: "Rechnung/Teilrechnung",
    cancelInvoice: "Rechnung stornieren",
    paidInvoice: "Rechnung bezahlt",
    activeUser: "Aktiver User",
    reminder1: "1. Mahnung",
    reminder2: "2. Mahnung",
    noReminder: "Rechnung",
    generate: "Generieren",
    breakPage: "Seitenumbruch",
    lockWork: "Monat abgeben",
    unlockWork: "Bericht entsperren",
    external: "Extern",
    internal: "Intern",
    effortRecord: "Aufwandsaufzeichnung",
    printAll: "Alles drucken",
    printpdf: "pdf Drucken",
    printdocx: "docx Drucken",
    printexcel: "xlsx Drucken",
    reloadTimesheet: "Zeiterfassung neu laden",
    previewInternal: "Vorschau intern",
    previewExternal: "Vorschau extern",
    preview: "Vorschau",
    holiday: "Mein Urlaub",
    timeReport: "Hours excerpt",
    myCompanyOnly: "Nur meine Firma",
    refresh: "Aktualisierung"
  },
  msg: {
    delAdr: "Möchten Sie diese Adresse wirklich löschen?",
    delOffer: "Möchten Sie dieses Angebot wirklich löschen?",
    delProject: "Möchten Sie dieses Projekt wirklich löschen?",
    delInvoice: "Möchten Sie diese Rechnung wirklich löschen?",
    delExpense: "Möchten Sie diese Ausgabe wirklich löschen?",
    delProduct: "Möchten Sie dieses Produkt wirklich löschen?",
    delGroup: "Möchten Sie diese Produktgruppe wirklich löschen?",
    delUser: "Möchten Sie diesen Benutzer wirklich löschen?",
    delContact: "Möchten Sie diesen Kontakt wirklich löschen?",
    delConnection: "Möchten Sie diese Verbindung wirklich löschen?",
    delHoliday: "Möchten Sie diesen Feiertag wirklich löschen?",
    required: "Pflichtfeld!",
    invalidMail: "Falsche E-Mail Adresse!",
    agreeTerm: "Sie müssen den Bedingungen von Archapp zustimmen.",
    registerSuccess:
      "Anmeldung erfolgreich! Bitte überprüfen Sie die Registrierungs-E-Mail, um den Link zu aktivieren.",
    noFile: "Keine Datei ausgewählt!",
    noCustomer: "Kein Kunde ausgewählt!",
    updateSuccess: "Element erfolgreich aktualisiert!",
    addSuccess: "Element erfolgreich hinzugefügt!",
    delSuccess: "Element erfolgreich gelöscht!",
    copySuccess: "Element erfolgreich kopiert!",
    uploadSuccess: "Element erfolgreich hochgeladen!",
    resetSuccess:
      "Erledigt! Bitte überprüfen Sie die E-Mail, um den Link zum Zurücksetzen zu erhalten.",
    changeSuccess: "Erfolgreich geändert!",
    activateSuccess: "Benutzer erfolgreich aktiviert!",
    exportSuccess: "Element erfolgreich exportiert!",
    cancelSuccess: "Element erfolgreich abgebrochen!",
    paidSuccess: "Bezahltes Element erfolgreich!",
    disablePopup:
      "Bitte deaktivieren Sie Ihren Popup-Blocker und versuchen Sie es erneut!",
    selectFile: "Wählen Sie eine Datei oder legen Sie diese hier ab...",
    dropFile: "Datei hier ablegen...",
    lastGenerated: "Letztes generiertes Dokument:",
    updateStatus: "Status aktualisieren",
    confirmPrint: "Alles drucken dauert eine Weile. Bitte haben Sie Geduld.",
    multipleCompanies:
      "Ihre E-Mail ist bei mehreren Unternehmen registriert. Bitte wählen Sie eine aus!",
    missingStartdate: "Fehlendes Startdatum!",
    duplicateProduct: "Duplikatprodukt!"
  },
  email: {
    salutation: "Sehr geehrte Damen und Herren",
    end: "Freundliche Grüsse",
    offerIntro:
      "Vielen Dank für Ihre Anfrage. Mit folgendem Link senden wir Ihnen die Offerte:",
    offerEnd: "Bei Fragen stehen wir Ihnen gerne zur Verfügung.",
    projectIntro:
      "Vielen Dank für den geschätzten Auftrag. Mit folgendem Link senden wir Ihnen die Auftragsbestätigung:",
    invoiceIntro:
      "Wie vereinbart, senden wir Ihnen mit folgendem Link die Rechnung zu:"
  },
  unit: {
    flatRate: "Pauschal",
    flat: "",
    hours: "Stunden",
    hr: "h",
    kilometres: "Kilometer",
    km: "km",
    squareMetres: "m2",
    m2: "m2",
    expenses: "nach Stunden",
    items: "Artikel",
    item: "St",
    page: "Seite"
  },
  status: {
    all: "alles",
    active: "aktiv",
    disabled: "deaktiviert",
    pending: "pendent",
    archive: "archiviert",
    deleted: "gelöscht",
    paid: "bezahlt",
    cancelled: "storniert",
    reminder1: "1. Mahnung",
    reminder2: "2. Mahnung",
    open: "offen",
    offset: "nicht verrechnet",
    reminder: "Mahnung",
    internal: "Intern"
  },
  login: {
    remind:
      "Bitte benutzen Sie Ihre Zugangsdaten zum Login. Wenn Sie noch kein Mitglied sind, können Sie sich registrieren.",
    forgotPass: "Passwort vergessen?",
    already: "Haben Sie bereits ein Konto?",
    noAcc: "Ich habe noch kein Konto.",
    guide: "Geben Sie Ihren Benutzernamen und Ihr Passwort ein."
  },
  forgotPass: {
    mail: "E-Mail",
    remind: "Bitte benutzen Sie Ihre E-Mail, um Ihr Passwort zurückzusetzen."
  },
  register: {
    welcome: "Willkommen bei Archapp.",
    desc:
      // eslint-disable-next-line max-len
      "In nur 2 einfachen Schritten richten Sie hier Ihre persönliche Archapp-Installation ein. Diese steht Ihnen 30 Tage lang kostenlos und unverbindlich zum Testen zur Verfügung - und wenn Sie davon überzeugt sind, dass Sie Ihre Angebote, Rechnungen etc. so schnell und bequem weiter schreiben wollen, können Sie Ihre Daten ohne Unterbrechung weiter nutzen - einfach nur smart.",
    company: "Ihre Firma",
    mail: "E-Mail",
    currency: "Ihre Währung",
    vat: "Die Mehrwertsteuer",
    vatDesc:
      "Sind Sie umsatzsteuerpflichtig? Wenn Sie diese Option aktivieren, kann dies nicht rückgängig gemacht werden. Sie können die Umsatzsteuerfunktionalität jedoch jederzeit später in den Einstellungen Ihrer Archapp-Installation aktivieren, wenn Sie diese Option derzeit nicht auswählen.",
    template: "Architektur Vorlage",
    templateDesc: "Dienstleistungen nach SIA",
    term: "Die Formalitäten",
    termDesc: "Ich akzeptiere die Bedingungen für die Nutzung von Archapp."
  },
  verifyRegister: {
    remind: "Bitte Passwort eingeben.",
    password: "Passwort",
    repeatPassword: "Wiederhole das Passwort"
  },
  dashboard: {
    hello: "Hallo",
    date: "Datum",
    customer: "Kunde",
    name: "Projekt",
    summary: "offene Summe",
    activeOffer: "aktive Offerten",
    activeProject: "laufende Projekte",
    activeInvoice: "gestellte Rechnungen",
    currentPass: "derzeitiges Passwort",
    newPass: "neues Kennwort",
    repeatPass: "wiederhole das neue Passwort",
    thousands: "tausende",
    sum: "Summe",
    invoiced: "verrechnet",
    remain: "offen",
    totalHour: "Stunden total",
    loggedHour: "Stunden geschrieben",
    net: "Netto",
    gross: "Brutto",
    sumNet: "Gesamtsumme Netto",
    sumGross: "Gesamtsumme Brutto",
    remainNet: "Ausstehend Netto",
    remainGross: "Ausstehend Brutto",
    sumActive: "Aktive Projekte",
    sumInvoice: "Rechnungen",
    reminder: "Mahnungen",
    notCharged: "Nicht verrechnet",
    projectHour: "Projektstunden Total",
    projectLogged: "Projektstunden geschrieben",
    projectHourDif: "Offene Stunden",
    sumActiveThisYear: "Projekte laufendes Jahr",
    projectHourDifThisYear: "Stunden laufendes Jahr",
    diffProjectInvoice: "Differenz Projektsumme und Offene Rechnungen",
    totalOpenInvoiceGross: "Offene Rechnungen Brutto",
    totalOpenReminderGross: "Offene Mahnungen Brutto",
    totalOvertime: "Zu ũberprũfende Projekte",
    totalOfferNetAmount: "Totale summe",
    totalOfferGrossAmount: "Totale brutto",
    totalProjectAmount: "Totale summe",
    totalRemainingAmount: "Resthonorar",
    totalOustandingNetAmount: "Offene netto",
    totalOustandingGrossAmount: "Offene brutto"
  },
  address: {
    name: "Firma/Name",
    company: "Name/Firma",
    category: "Kategorie",
    type: "Typ",
    address: "Adresse",
    otheradr: "Adresszusatz",
    postcode: "Postleitzahl",
    place: "Ort",
    mail: "E-Mail",
    phone: "Tel.",
    fax: "Fax",
    country: "Land",
    web: "Webseite",
    comment: "Bemerkung",
    contact: "Kontaktperson",
    title: "Titel",
    firstName: "Vorname",
    lastName: "Nachname",
    contactName: "Nachname",
    givenName: "Vorname",
    mobile: "Mobil",
    wmail: "E-Mail",
    prmail: "Private E-Mail",
    dob: "Geburtsdatum",
    internal: "intern",
    typeCompany: "Firma",
    typePerson: "Privatperson",
    typeMr: "Herr",
    typeMs: "Frau",
    typeFamily: "Familie",
    catCustomer: "Kunde",
    catAcquisition: "Akquise",
    catSupplier: "Lieferant",
    actions: "Aktionen"
  },
  offer: {
    active: "Aktiv",
    name: "Firma/Name",
    title: "Projekt",
    number: "Projekt Nr.",
    date: "Datum",
    status: "Status",
    customer: "Anschrift Kunde",
    offer: "Projekttitel",
    location: "Projektstandort",
    internalDesc:
      "Interne Beschreibung (erscheint nicht auf der Bestellung/Rechnung)",
    additional: "Falls vorhanden ersetzt dieser Text die Rubrik",
    acceptText1:
      "Der Kunde hat das Angebot angenommen, somit wird der Auftrag nun in ein Projekt umgewandelt.",
    acceptText2:
      "Bei Bedarf können Sie ein Fertigstellungsdatum für dieses Projekt wählen. Ansonsten lassen Sie das Feld einfach leer.",
    projectComplete: "Projektabschluss",
    from: "vom",
    addcostInvoice: "Nebenkostenrechnung",
    actions: "Aktionen",
    messerliCal: "Berechnung nach Baukosten",
    freeCal: "Anlagekosten",
    kilometers: "Fahrkilometer",
    genType: "auf Stundenbasis",
    sum: "Summe",
    net: "Netto",
    gross: "Brutto"
  },
  project: {
    name: "Kunde",
    customer: "Anschrift Kunde",
    title: "Projekt",
    project: "Projekt",
    assignee: "Nutzer",
    date: "Datum",
    location: "Standort",
    number: "Projekt Nr.",
    created: "Erstellt am",
    ended: "Ende am",
    internalDesc:
      "Interne Beschreibung (erscheint nicht auf der Bestellung/Rechnung)",
    invoiceText1:
      "Um den gesamten Betrag in Rechnung zu stellen, klicken Sie ohne Änderung auf den OK-Button. Wenn Sie eine Teilrechnung erstellen möchten, geben Sie den in Rechnung zu stellenden Betrag unten an.",
    invoiceText2: "Bemerkung zur Mehrwertsteuer!",
    attention: "Achtung",
    attentionText:
      "Teilrechnungen können nur für Projekte mit einheitlichem Mehrwertsteuersatz erstellt werden. Wenn Sie eine Teilrechnung erstellen, ist der Satz für diese Rechnung fix gesetzt und kann weder geändert, noch können neue Positionen mit einem anderen MwSt.-Satz hinzugefügt werden.",
    invoiceAmount: "Rechnungsbetrag (exkl. MwSt.)",
    percent: "Prozent",
    service: "Dienstleistungen",
    hour: "Stunde",
    totalAmount: "Betrag",
    remaining: "Restbetrag",
    completion: "Projektabschluss",
    actions: "Aktionen",
    additional: "Nebenkostenrechnung",
    invoice: "Rechnung",
    from: "vom",
    start: "Anfang",
    end: "Ende",
    noRecord: "Keine Aufzeichnung verfügbar.",
    rate: "Rate",
    task: "Dienstleistung",
    spent: "verbraucht",
    sum: "Summe",
    totalHour: "Gesamtstunden",
    totalRate: "Kosten",
    invoiced: "Verrechnet",
    remain: "offen",
    allHour: "Stunden total",
    loggedHour: "Stunden geschrieben",
    beginOfTime: "Von Anfang an",
    thisMonth: "Diesen Monat",
    lastMonth: "Letzter Monat",
    net: "Netto",
    gross: "Brutto",
    noConnection: "Keine Verbindung",
    connections: "Verbindungen",
    hourRate: "Tarif",
    charged: "verrechnet",
    orderTotal: "Auftragssumme",
    remainingClaim: "Restforderung",
    invoicedTotal: "Rechnungssumme"
  },
  invoice: {
    name: "Firma/Name",
    title: "Projekt",
    number: "Projekt Nr.",
    charged: "RG Datum",
    due: "Fällig am",
    paid: "Bezahlt am",
    amount: "Betrag mit Mwst.",
    amountNet: "Betrag ohne Mwst.",
    invoice: "Rechnung",
    customer: "Anschrift Kunde",
    date: "Datum",
    location: "Standort",
    payable: "zahlbar innerhalb",
    days: "Tagen",
    slipNumber: "Einzahlungsschein-Referenznummer",
    internalDesc:
      "Interne Beschreibung (erscheint nicht auf der Bestellung/Rechnung)",
    invoiceNum: "Rechnungsnummer",
    refNum: "Referenznummer",
    totalNet: "Netto",
    totalGross: "Brutto",
    valueDate: "Datum",
    comment: "Bemerkung",
    actions: "Aktionen",
    history: "Zahlungsinformationen",
    partialInvoice: "Teilrechnung",
    finalInvoice: "Schlussrechnung",
    paidHistory: "Bezahlter Verlauf",
    remain: "Restbetrag",
    listHistory: "Rechnungsübersicht",
    from: "vom",
    customTitle: "Benutzerdefinierter Titel",
    customDate: "Benutzerdefiniertes Datum",
    includeInternalHourReport: "Fügen Sie einen internen Stundenbericht hinzu",
    includeExternalHourReport: "Fügen Sie einen externen Stundenbericht hinzu",
    reportFrom: "Bericht von",
    reportTo: "Bericht an",
    paidTitle: "Bezahlte",
    qr: "QR Nr."
  },
  expense: {
    name: "Empfänger",
    paymentRef: "Zahlungsreferenz",
    date: "Datum",
    amount: "Betrag",
    recipient: "Bezeichnung",
    comment: "Anmerkungen",
    attachment: "Anhang",
    upload: "Anhang hinzufügen",
    actions: "Aktionen"
  },
  product: {
    name: "Produktbezeichnung",
    gname: "Bezeichnung Produktgruppe",
    desc: "Beschreibung",
    group: "Gruppe",
    unit: "Einheit",
    vat: "MWST",
    price: "Preis",
    rate: "MwSt. Satz",
    included: "Inklusive Dienstleistungen",
    allServices: "Alle Dienstleistungen",
    actions: "Aktionen",
    task: "Aufgabe",
    folder: "Ordner"
  },
  timesheet: {
    startDate: "Start",
    endDate: "Ende",
    project: "Projektname",
    service: "Projekt + Projektphase",
    task: "Dienstleistung",
    comment: "Anmerkungen",
    total: "Gesamt",
    mustWork: "Soll Arbeitszeit 100%",
    dailyTotal: "Gesamtstunden",
    diff: "Differenz",
    assignee: "Nutzer",
    thisWeek: "Diese Woche",
    thisMonth: "Diesen Monat",
    month: "Monat",
    year: "Jahr",
    users: "Benutzer",
    noUser: "Kein Benutzer verfügbar.",
    confirmReport:
      "Bestätigen Sie Ihre Übermittlung. Diese Aktion kann nicht zurückgesetzt werden.",
    billable: "Abrechenbar",
    internal: "Intern",
    all: "Alle",
    flexible: "Gleitzeitstunden",
    personal: "Meine Zeiterfassung",
    viewOption: "Ansichtsoption",
    showAll: "Alles anzeigen"
  },
  report: {
    listUser: "Liste der aktuellen Mitarbeiter",
    listJob: "Liste der aktuellen Mitarbeiter mit Honorarkategorie und Tarif",
    listAddress: "Liste der Adressen",
    listProject: "Liste der Projekte",
    listOffer: "Liste der Angebote",
    listInvoice: "Liste der Rechnungen",
    listEffortExternal:
      "Aufwand extern nach Aufträgen, Teilleistungen und Honorarkategorien",
    listEffortInternal:
      "Aufwand intern nach Aufträgen, Teilleistungen und Honorarkategorien",
    listPhaseEffort: "Aufwand nach Teilleistungen",
    listUserEffort: "Aufwand nach Mitarbeitern und Aufträgen",
    listHoliday: "Aufwand nach Zusammenstellung Gleitzeit- und Feriensaldo",
    timeOnly: "Nur Zeit",
    byHoliday: "nach Zusammenstellung Gleitzeit- und Feriensaldo",
    byUserEffort: "nach Aufwand",
    singleProject: "Projekte",
    singleProjectSummary: "Projekte Zusammenfassung",
    singleInvoice: "Rechnung",
    singleAddress: "Adresse",
    singleOffer: "Offerte",
    singleUser: "Benutzer",
    byPartial: "nach Aufträgen, Teilleistungen und Honorarkategorien",
    byPhase: "nach Teilleistungen",
    byTask: "nach Aufgaben",
    byAllInvoices: "Alle Rechnungen",
    internal: "Interner Export",
    external: "Externer Export",
    projectSummary: "Projektübersicht"
  },
  timeOverview: {
    projectTime: "Abrechenbare Zeit",
    productive: "Produktive Zeit",
    unproductive: "Unproduktive Zeit",
    revenue: "Einnahmen",
    invoiced: "Fakturiert",
    uninvoiced: "Nicht in Rechnung gestellt",
    project: "Projekt",
    number: "Projekt Nr.",
    employee: "Mitarbeiter",
    unbillable: "Nicht abrechenbare Zeit",
    totalHours: "Soll Stunden",
    thisWeek: "Diese Woche",
    thisMonth: "Diesen Monat",
    lastMonth: "Letzter Monat",
    thisYear: "Dieses Jahr",
    today: "Heute",
    notedHours: "Verwendete Stunden",
    totalAvailable: "Verfügbare Stunden",
    all: "Alle",
    position: "Position",
    totalSum: "Gesamtsumme",
    hourProject: "Stunden Projekt",
    hourUsed: "% Stunden verwendet",
    rate: "Gewinn/Verlust",
    submitted: "Berichte Mitarbeiter",
    status: "Status"
  },
  settings: {
    master: "Stammdaten",
    user: "Benutzer",
    unit: "Einheit",
    rate: "MwSt. Satz",
    offer: "Offerte",
    order: "Projekt - Auftragsbestätigung",
    invoice: "Rechnung",
    timesheet: "Zeiterfassung",
    pdf: "PDF Layout",
    sharing: "Teilen",
    product: "Produkte",
    form: {
      master: {
        company: "Firma",
        address: "Adresse",
        place: "Ort",
        country: "Land",
        postcode: "Postleitzahl",
        phone: "Tel",
        fax: "Fax",
        mail: "E-Mail",
        web: "Webseite",
        currency: "Währung",
        bank: "Bank",
        clearing: "Clearing-Nummer",
        iban: "IBAN/Kontonummer",
        revenue: "Umsatz (Ohne Spesen)",
        year: "Jahr",
        data: "Daten",
        options: "Optionen",
        allow_to_use_other_currencies:
          "Verwendung anderer Währungen in Dokumenten zulassen"
      },
      user: {
        firstName: "Vorname",
        lastName: "Nachname",
        level: "Rolle",
        active: "Aktiv",
        lvAuth: "Benutzerrechte",
        text1:
          "Die Berechtigungsstufe bestimmt, auf welche Funktionen der neue Benutzer zugreifen kann:",
        text2: "Admin: unbegrenzter Zugriff.",
        text3:
          "Projektleiter: ohne Zugriff auf Dienste, Listen und Einstellungen.",
        text4: "Mitarbeiter: Zeiterfassung.",
        lvAdmin: "Admin",
        lvPM: "Projektleiter",
        lvEmployee: "Mitarbeiter",
        actions: "Aktionen",
        maxWeekly: "Anstellung %",
        maxDaily: "Arbeitszeit pro Tag",
        postcode: "Postleitzahl",
        phone: "Tel",
        place: "Ort",
        address: "Adresse",
        job: "Beruf",
        dob: "Geburtstag",
        startWork: "Anstellungsdatum",
        endWork: "Kündigungsdatum",
        holidays: "Ferientage",
        workload: "Tage pro Woche",
        email: "E-Mail",
        startDate: "Start",
        endDate: "Ende",
        latestFlex: "Verbleibende Gleitzeit",
        amount: "Menge",
        latestHours: "Vorige Stunden",
        rsPlanning: "Leistung %"
      },
      unit: {
        desc: "Beschreibung",
        unit: "Einheit",
        visible: "Sichtbar",
        default: "Standard",
        timeRecord: "Zeiterfassung",
        timeRecordActive: "Zeiterfassung aktiviert?",
        text1:
          "Diese Einstellung aktiviert den Account für das Zeiterfassungssystem.",
        text2:
          "daher eignet es sich besonders für Einheiten, die nach Aufwand oder Stunden abgerechnet werden."
      },
      vat: {
        rate: "MwSt. Satz",
        visible: "Sichtbar",
        default: "Standard",
        percent: "Prozent"
      },
      offer: {
        label: "Beschreibung",
        value: "Wert",
        dropdownSetting: "Nebenkosten, Fahrkilometer",
        dropdownLabel: "Varianten / Inhalt",
        addcostSetting: "Nebenkosten, Parameter",
        percentLabel: "Varianten / Inhalt",
        subtitle: "Untertitel"
      },
      project: {
        duration: "Projektdauer in Tagen",
        begin: "Anfang des Textes",
        end: "Ende des Textes",
        address: "Anschrift"
      },
      invoice: {
        duration: "Rechnung zu bezahlen, in Tagen",
        begin: "Anfang des Textes",
        end: "Ende des Textes",
        durationRe1: "Mahnung 1, in Tagen",
        durationRe2: "Mahnung 2, in Tagen",
        beginRe1: "Mahnung 1, Text Anfang",
        endRe1: "Mahnung 1, Ende des Textes",
        beginRe2: "Mahnung 2, Text Anfang",
        endRe2: "Mahnung 2, Ende des Textes",
        qr: "QR-Code einfügen",
        hint:
          "Verwenden Sie [duration], um den Deadline-Wert in Ihrem Text zu ersetzen"
      },
      timesheet: {
        working: "Soll Arbeitszeit 100%",
        maxWork: "Maximale Arbeitszeit pro Woche",
        holidays: "Ferien",
        desc: "Beschreibung",
        date: "Datum",
        jobs: "Arbeitsplätze",
        title: "Titel",
        rate: "Rate",
        defaultHoliday: "Standard-Urlaubsaufgabe"
      },
      pdf: {
        logo: "Logo (PNG oder JPEG)",
        scale: "Skalierung",
        logoPos: "Position Logo",
        adrPos: "Position Adresse",
        font: "PDF Schriftart",
        footerCustom: "PDF Fusszeile",
        headerCustom: "PDF Kopfzeile",
        pgNum: "Seite",
        showFooter: "Fusszeile anzeigen",
        showHeader: "Kopfzeile anzeigen",
        showNum: "Seite anzeigen"
      },
      sharing: {
        generateToken: "Token generieren",
        connections: "Verbindungsliste",
        connection: "Verbindung",
        actions: "Aktionen"
      },
      product: {
        folder: "Ordner",
        name: "Name"
      }
    }
  },
  customer: {
    name: "Firma/Name",
    category: "Kategorie",
    address: "Adresse",
    postcode: "Postleitzahl",
    place: "Ort",
    mail: "E-Mail",
    phone: "Tel"
  },
  quoteItems: {
    service: "Dienstleistungen",
    optional: "Optional",
    unit: "Einheit",
    amount: "Menge",
    vat: "MWST",
    price: "Preis",
    cost: "Kosten",
    discountPercent: "Rabatt in %",
    discount: "Rabatt",
    applyDiscount: "Rabatt anwenden",
    net: "Total Netto",
    gross: "Total Brutto inkl. MWST",
    titleCost: "Total Honorarsumme:",
    netRemaining: "Netto Restbetrag",
    grossRemaining: "Brutto Restbetrag",
    invoice: "Rechnung",
    partial: "Teilrechnung",
    from: "vom",
    netpdf: "Total exkl. MwSt.",
    vatpdf: "Mehrwertsteuer",
    grosspdf: "Total inkl. MwSt.",
    total: "Total inkl. Rabatt",
    totalNoDiscount: "Total exkl. Rabatt",
    finalInvoice: "Schlussrechnung",
    planning: "Termin"
  },
  months: {
    jan: "Jan.",
    feb: "Feb.",
    mar: "Mär.",
    apr: "Apr.",
    may: "Mai",
    jun: "Jun.",
    jul: "Jul.",
    aug: "Aug.",
    sep: "Sep.",
    oct: "Okt.",
    nov: "Nov.",
    dec: "Dez.",
    janFull: "Januar",
    febFull: "Februar",
    marFull: "März",
    aprFull: "April",
    mayFull: "Mai",
    junFull: "Juni",
    julFull: "Juli",
    augFull: "August",
    sepFull: "September",
    octFull: "Oktober",
    novFull: "November",
    decFull: "Dezember"
  },
  countries: {
    AF: "Afghanistan",
    AX: "Åland",
    AL: "Albanien",
    DZ: "Algerien",
    AS: "Amerikanisch-Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarktis (Sonderstatus durch Antarktisvertrag)",
    AG: "Antigua und Barbuda",
    AR: "Argentinien",
    AM: "Armenien",
    AW: "Aruba",
    AU: "Australien",
    AT: "Österreich",
    AZ: "Aserbaidschan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesch",
    BB: "Barbados",
    BY: "Weißrussland",
    BE: "Belgien",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivien",
    BA: "Bosnien und Herzegowina",
    BW: "Botswana",
    BV: "Bouvetinsel",
    BR: "Brasilien",
    IO: "Britisches Territorium im Indischen Ozean",
    BN: "Brunei",
    BG: "Bulgarien",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Kambodscha",
    CM: "Kamerun",
    CA: "Kanada",
    CV: "Kap Verde",
    KY: "Kaimaninseln",
    CF: "Zentral­afrikanische Republik",
    TD: "Tschad",
    CL: "Chile",
    CN: "Volksrepublik China",
    CX: "Weihnachtsinsel",
    CC: "Kokosinseln",
    CO: "Kolumbien",
    KM: "Komoren",
    CG: "Kongo, Republik",
    CD: "Kongo, Demokratische Republik",
    CK: "Cookinseln",
    CR: "Costa Rica",
    CI: "Elfenbeinküste",
    HR: "Kroatien",
    CU: "Kuba",
    CY: "Zypern",
    CZ: "Tschechien",
    DK: "Dänemark",
    DJ: "Dschibuti",
    DM: "Dominica",
    DO: "Dominikanische Republik",
    EC: "Ecuador",
    EG: "Ägypten",
    SV: "El Salvador",
    GQ: "Äquatorialguinea",
    ER: "Eritrea",
    EE: "Estland",
    ET: "Äthiopien",
    FK: "Falklandinseln",
    FO: "Färöer",
    FJ: "Fidschi",
    FI: "Finnland",
    FR: "Frankreich",
    GF: "Französisch-Guayana",
    PF: "Französisch-Polynesien",
    TF: "Französische Süd- und Antarktisgebiete",
    GA: "Gabun",
    GM: "Gambia",
    GE: "Georgien",
    DE: "Deutschland",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Griechenland",
    GL: "Grönland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey (Kanalinsel)",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard und McDonaldinseln",
    VA: "Vatikanstadt",
    HN: "Honduras",
    HK: "Hongkong",
    HU: "Ungarn",
    IS: "Island",
    IN: "Indien",
    ID: "Indonesien",
    IR: "Iran",
    IQ: "Irak",
    IE: "Irland",
    IM: "Insel Man",
    IL: "Israel",
    IT: "Italien",
    JM: "Jamaika",
    JP: "Japan",
    JE: "Jersey (Kanalinsel)",
    JO: "Jordanien",
    KZ: "Kasachstan",
    KE: "Kenia",
    KI: "Kiribati",
    KP: "Korea, Nord",
    KR: "Korea, Süd",
    KW: "Kuwait",
    KG: "Kirgisistan",
    LA: "Laos",
    LV: "Lettland",
    LB: "Libanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyen",
    LI: "Liechtenstein",
    LT: "Litauen",
    LU: "Luxemburg",
    MO: "Macau",
    MK: "Nordmazedonien",
    MG: "Madagaskar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Malediven",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshallinseln",
    MQ: "Martinique",
    MR: "Mauretanien",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexiko",
    FM: "Mikronesien",
    MD: "Moldau",
    MC: "Monaco",
    MN: "Mongolei",
    MS: "Montserrat",
    MA: "Marokko",
    MZ: "Mosambik",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Niederlande",
    AN: "Netherlands Antilles",
    NC: "Neukaledonien",
    NZ: "Neuseeland",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolkinsel",
    MP: "Nördliche Marianen",
    NO: "Norwegen",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palästina",
    PA: "Panama",
    PG: "Papua-Neuguinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippinen",
    PN: "Pitcairninseln",
    PL: "Polen",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Katar",
    RE: "Réunion",
    RO: "Rumänien",
    RU: "Russland",
    RW: "Ruanda",
    SH: "St. Helena, Ascension und Tristan da Cunha",
    KN: "St. Kitts und Nevis",
    LC: "St. Lucia",
    PM: "Saint-Pierre und Miquelon",
    VC: "St. Vincent und die Grenadinen",
    WS: "Samoa",
    SM: "San Marino",
    ST: "São Tomé und Príncipe",
    SA: "Saudi-Arabien",
    SN: "Senegal",
    CS: "Serbia and Montenegro",
    SC: "Seychellen",
    SL: "Sierra Leone",
    SG: "Singapur",
    SK: "Slowakei",
    SI: "Slowenien",
    SB: "Salomonen",
    SO: "Somalia",
    ZA: "Südafrika",
    GS: "Südgeorgien und die Südlichen Sandwichinseln",
    ES: "Spanien",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Spitzbergen und Jan Mayen",
    SZ: "Eswatini",
    SE: "Schweden",
    CH: "Schweiz",
    SY: "Syrien",
    TW: "Republik China",
    TJ: "Tadschikistan",
    TZ: "Tansania",
    TH: "Thailand",
    TL: "Osttimor",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad und Tobago",
    TN: "Tunesien",
    TR: "Türkei",
    TM: "Turkmenistan",
    TC: "Turks- und Caicosinseln",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "Vereinigte Arabische Emirate",
    GB: "Vereinigtes Königreich",
    US: "Vereinigte Staaten",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Usbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Britische Jungferninseln",
    VI: "Amerikanische Jungferninseln",
    WF: "Wallis und Futuna",
    EH: "Westsahara",
    YE: "Jemen",
    ZM: "Sambia",
    ZW: "Simbabwe"
  }
};
